import React, { useEffect, useState } from "react";

import Arrow from "components/icons/Arrow";
import { black } from "theme/colors";

import { graphql } from "gatsby";

import HTMLReactParser from "html-react-parser";

const WorkServicesTemplate = (props) => {
  //PROPS
  const {
    data: {
      page: { privacy, title }
    }
  } = props;
  //PROPS
  const [hasBack, setBack] = useState(true);
  useEffect(() => {
    if (window.history.length < 3) {
      setBack(false);
    }
  }, []);

  return (
    <>
      {hasBack && (
        <div className="return-section">
          <button onClick={() => window.history.go(-1)}>
            <Arrow color={black.default} />
          </button>
        </div>
      )}
      <main className="main">
        <div className="container container--justify container--800">
          <h1 className="h1 h1-underline">{title}</h1>
          {privacy.text && HTMLReactParser(privacy.text)}
        </div>
      </main>
    </>
  );
};

export const query = graphql`
  query privacy($id: String) {
    page: wpPage(id: { eq: $id }) {
      privacy {
        text
      }
      title
    }
    allWpSkill {
      edges {
        node {
          slug
          name
          id
          language {
            code
          }
        }
      }
    }
  }
`;

export default WorkServicesTemplate;
